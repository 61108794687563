.payment-result {

    h1 {
        text-align: center;
        margin-bottom: 2rem;
    }

    p {
        text-align: center;
        margin-bottom: 1rem;
    }

    .error-message {
        line-height: 1.5rem;

        i {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            font-size: 0.75rem;
            vertical-align: top;
        }
    }
}

.payment-method {
    padding: 16px;

    .payment-method-header {
        display: flex;

        * {
            display: block;
            margin-right: 8px;
        }

        .radio-button {
            height: 16px;
            width: 16px;
            margin: auto 0;
            margin-right: 16px;
            border: 1px solid #b9c4c9;
            border-radius: 50%;
        }

        img {
            height: 26px;
            width: 40px;
            border: 1px solid #e6e9eb;
            border-radius: 3px;
        }
    }
    
    &.--selected {
        .payment-method-header {
            font-weight: 500;

            .radio-button {
                border: 5px solid #06f;
            }
        }

        .payment-method-content {
            padding-top: 12px;
        }
    }
}

.payment-link-details {
    p {
        line-height: 1.5rem;
        margin-bottom: 0.75rem;
    }

    .credit-card-detail,
    .payment-panel {
        margin: 2rem auto;
        max-width: 500px;
    }

    .payment-panel {
        .level-left {
            flex-shrink: 1;

            .level-item {
                flex-shrink: 1;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .level-right {
            padding-left: 1rem;

            button {
                width: 12rem;
                padding: 0 1rem;
                color: var(--btnprimary-text-color);
                background-color: var(--btnprimary-bckg-color);

                &disabled {
                    background-color: var(--btnprimarydisabled-bckg-color);
                }
            }
        }
    }
}

.thank-you-message {
    text-align: center;
    color: #072f53;
    font-size: 18px;
    margin-top: 20px;

    h2 {
        font-size: 24px;
        margin: 10px 0;
    }

    p {
        margin: 0;
    }
}
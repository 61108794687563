.payment-link {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    header {
        nav {
            padding: 0.5rem;
            border-bottom-style: solid;
            border-bottom-color: var(--header-border-bottom-color);
            border-bottom-width: var(--header-border-bottom-width);
            background-color: var(--header-bckg-color);

            img {
                height: var(--header-logo-height);
                width: var(--header-logo-width);
                margin: var(--header-logo-margin);
            }

            .phone {
                font-size: 20px;
                background-color: var(--header-phone-bckg-color);
                color: var(--header-phone-text-color);
                border-radius: var(--header-phone-border-radius);
                padding: var(--header-phone-padding);
            }
        }
    }

    main {
        flex: 1;
        padding: 2rem;
    }

    footer {
        padding: 1rem;
        border-top: 1px solid rgba(0,0,0,.15);

        span::after {
            content: '|';
            margin: 0 1rem;
        }
    }
}
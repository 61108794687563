.payment-method-selector {
    margin: 2rem auto;
    max-width: 500px;

    ul {
        li {
            border: 1px solid #e6e9eb;
            border-bottom-width: 0;
            cursor: pointer;

            &.--selected,
            &.--unselected {
                margin-bottom: 8px;
                border-width: 1px;
                border-radius: 12px;
            }

            &.--selected {
                background-color: #f7f8f9;
                cursor: inherit;
            }

            &:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
    
            &:last-child {
                border-bottom-width: 1px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }
}

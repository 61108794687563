.payment-link-invalid {
    h1 {
        text-align: center;
        margin-bottom: 2rem;
    }

    p {
        text-align: center;
        margin-bottom: 1rem;
    }
}
.bpay-panel {
  margin-bottom: 20px;
  border: 2px solid #072f53;
  padding: 10px;
  border-radius: 8px; /* Optional: for a rounded border look */

  .bpay-header {
      display: flex;
      align-items: center;
      border-bottom: 2px solid #072f53;
      padding-bottom: 10px;
      margin-bottom: 10px;

      .bpay-logo {
          max-height: 50px;
          margin-right: 10px;
      }

      .bpay-info {
          p {
              color: #002e67;
              margin: 0;
          }
      }
  }

  .bpay-footer {
      color: #002067;
      font-size: 14px; /* Slightly smaller font for footer text */

      a {
          color: inherit;
          text-decoration: underline;
      }
  }

  .bpay-button {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #072f53;
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
          background-color: #002e67;
      }
  }
}

.loading-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    min-height: 100vh;

    i {
        color:lightgray;
    }
}
